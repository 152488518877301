<template>
  <div class="church-presentation-template">
    <page-body class>
      <address-form
        v-if="translations.components['address-form'].tcSelectAType"
        :individualName="this.individualProfile.formal_name"
        :individualKey="this.individualProfile.ind_key"
        :userKey="this.userIndKey"
        :address="this.userSelectedAddress"
        :i18n="translations.components"
      ></address-form>
    </page-body>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import addressForm from '@/components/AddressForm.vue'
import iTooltip from '@/assets/svgs/i-tooltip.vue'
import pageBody from '@/components/page-components/PageBody.vue'
import pageFooter from '@/components/page-components/PageFooter.vue'
import pageHeader from '@/components/page-components/PageHeader.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-individual-address',
  mixins: [translationMixin],
  data() {
    return {
      translations: {
        components: {
          'address-form': {},
        },
      },
    }
  },
  methods: {
    ...mapActions({
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
  },
  computed: {
    ...mapGetters({
      individualProfile: 'membership/individualProfile',
      prefCulture: 'user/userPreferredCulture',
      userIndKey: 'user/userId',
      userName: 'user/userName',
      userSelectedIndividualKey: 'user/userSelectedIndividualKey',
      userSelectedAddress: 'user/userSelectedAddress',
    }),
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([await this.getComponentTranslations('address-form')]).then((results) => {
        const componentTranslations = results[0]
        this.$set(this.translations, 'components', componentTranslations)
      })
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  components: {
    addressForm: addressForm,
    iTooltip: iTooltip,
    pageBody: pageBody,
    pageFooter: pageFooter,
    pageHeader: pageHeader,
  },
}
</script>

<style scoped>
.page-body {
  padding: 80px 15px;
}
</style>
